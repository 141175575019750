<template>
  <div class="login-page">
    <div class="login-wrapper">
      <Logo modifier="login-logo" />
      <h1 class="login-title">Войти в кабинет</h1>
      <div class="line" />
      <form class="login-form">
        <TextInput
          id="email"
          v-model:value="user.login"
          label="Email или Логин"
          placeholder="Введите ваш email или логин"
          :error="errors.login"
        />
        <TextInput
          id="password"
          v-model:value="user.password"
          label="Пароль"
          placeholder="Введите ваш пароль"
          type="password"
          :error="errors.password"
        />
        <p v-if="errors.text">{{ errors.text }}</p>
        <router-link to="/forgot-password" class="login-forgot-link">
          Забыли пароль?
        </router-link>
        <Button
          text="Войти"
          className="btn-primary"
          class="_filled"
          :disabled="loading"
          @click.prevent="logIn"
        />
      </form>
    </div>
  </div>
</template>

<script>
import TextInput from "../components/TextInput";
import Logo from "../components/Logo";
import Button from "../components/Button";

import axios from "axios";

export default {
  name: "Login",
  components: {
    Logo,
    TextInput,
    Button,
  },
  data() {
    return {
      user: {
        login: "",
        password: "",
      },
      errors: {},
      loading: false,
    };
  },
  async created() {
    const { data: admin } = await axios.get("/api/profile");
    if (admin) {
      location.href = process.env.VUE_APP_NEW_ADMIN_SITE_HOME;
    }
  },
  methods: {
    async logIn() {
      this.loading = true;
      this.errors = {};
      try {
        await axios.post("/api/log-in", this.user);
        location.href = process.env.VUE_APP_NEW_ADMIN_SITE_HOME;
      } catch (error) {
        const status = (error.response && error.response.status) || 500;
        if (status === 400) {
          this.errors = error.response.data;
        } else {
          this.$router.push(`/error/${status}`);
        }
      }
      this.loading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/styles/vars";

.login {
  &-page {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;

    @media screen and (max-width: 767px) {
      padding: 15px;
    }
  }

  &-wrapper {
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    box-shadow: 0 55px 100px rgba(133, 133, 175, 0.2);
    border-radius: 4px;
    width: 500px;

    @media screen and (max-width: 767px) {
      padding: 30px 20px;
    }

    .line {
      width: 100%;
      height: 1px;
      background: fade-out(#8080a7, 0.6);
      margin-top: 10px;
    }
  }

  &-title {
    margin-top: 40px;
    font-weight: 500;
    color: $color-base;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 28px;
    }

    @media screen and (min-width: 768px) {
      font-size: 32px;
      line-height: 44px;
    }
  }

  &-form {
    margin-top: 20px;
    width: 100%;

    p {
      color: red;
    }

    .fieldset {
      max-width: 100%;
    }
  }

  &-forgot-link {
    font-size: 14px;
    line-height: 16px;
    color: $color-link;
    margin-top: 10px;
    display: inline-block;
  }
}
</style>
